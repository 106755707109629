@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

:root {
  --brand-dark: #595b79;
  --brand-light: #f3f1ed;
  --brand-dark-rgb: 89, 91, 121;
  --brand-light-rgb: 243, 241, 237;
  --brand-text: #363849;
  --brand-text-rgb: 54, 56, 73;
  --brand-danger: #c07374;
  --brand-danger-rgb: 192, 115, 116;
  --brand-success: #b6cca4;
  --brand-success-rgb: 179, 202, 161;

  --full-height: 100%;

  --test-border: 0px solid #fff;
  --test-border-dotted: 0px dotted #fff;
}

html,
body {
  margin: 0px;
  font-family: Roboto, Arial, sans-serif;
  background-color: var(--brand-dark);
  height: var(--full-height);
}
button:focus-visible {
  outline: 2px solid rgba(158, 157, 189, 0.5) !important;
  outline: -webkit-focus-ring-color auto 5px !important;
}
a {
  text-decoration: none;
}

#root {
  height: var(--full-height);
}

.full-height {
  height: var(--full-height);
  min-height: var(--full-height);
}

.pageFadeIn {
  animation: 0.5s fadeIn forwards;
  animation-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.pageFadeOut {
  animation: 0.3s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-1000px, 0);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  to {
    opacity: 0;
    transform: translate(-1000px, 0);
  }
}

@media (min-width: 1024px) {
  .pageFadeIn {
    animation: 0.4s fadeIn forwards;
    animation-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }

  .pageFadeOut {
    animation: 0.1s fadeOut forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(0, -1000px);
    }

    to {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      /* transform: translate(0px, 0px); */
    }

    to {
      opacity: 0;
      /* transform: translate(0, -1000px); */
    }
  }
}
