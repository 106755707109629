.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  border: var(--test-border-dotted);
  border-color: green;
}

.form {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1em;
  padding: 2em 1em;
  padding-bottom: 3em;
  background: #fff url(mobile-page-bg.svg) no-repeat top right;
  border-radius: 1em;

  border: var(--test-border-dotted);
  border-color: red;
}

.header {
  padding-bottom: 1rem;
}

.step {
  /* padding: 1rem 0; */
}

.header h1 {
  color: var(--brand-dark);
  font-size: 1.8em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
}

.header h2 {
  color: rgba(var(--brand-dark-rgb), 0.65);
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.field {
  display: flex;
  flex-direction: column;
}

.group {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
}

.group small {
  color: rgba(var(--brand-dark-rgb), 0.65);
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 0.25rem;
  text-align: left;
  padding-left: 1rem;
}

.group input {
  width: 100%;
  border-radius: 25px;
  border: 2px solid rgba(var(--brand-dark-rgb), 0.65);
  background: var(--brand-light);
  padding: 0.5em 1em;
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2em;
  color: var(--brand-text);
  transition: all 0.2s ease;
}
.group input::placeholder {
  color: #b7b7b7;
}
.group input:focus {
  border: 2px solid var(--brand-dark);
  background: #fbfbfb;
  box-shadow: 0px 0px 0px 0.25em rgba(var(--brand-dark-rgb), 0.25);
  outline: none;
}

.group[state="error"] input {
  border: 2px solid var(--brand-danger);
  color: var(--brand-danger);
}
.group[state="error"] input:focus {
  box-shadow: 0px 0px 0px 0.25em rgba(var(--brand-danger-rgb), 0.25);
}
.group[state="error"] label,
.group[state="error"] small {
  color: var(--brand-danger);
}

.form[disabled] input {
  opacity: 0.65;
}

.group select {
  width: 100%;
  border-radius: 25px;
  border: 2px solid rgba(var(--brand-dark-rgb), 0.65);
  background: var(--brand-light);
  padding: 0.5em 1em;
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2em;
  color: var(--brand-text);
  transition: all 0.2s ease;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23808080' d='M2 0L0 2h4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1em top 50%;
  background-size: 0.65em auto;
}

.group select:focus {
  border: 2px solid var(--brand-dark);
  background: #fbfbfb;
  box-shadow: 0px 0px 0px 0.25em rgba(var(--brand-dark-rgb), 0.25);
  outline: none;
}

.group select:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.group select option {
  color: var(--brand-text);
  background: var(--brand-light);
}

.group label {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5em;
  color: rgba(var(--brand-dark-rgb), 0.85);
  margin-bottom: 0.25em;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
  gap: 1em;
}

.footer span,
.footer a {
  color: rgba(var(--brand-dark-rgb), 0.85);
  font-size: 0.85em;
}

.footer a {
  text-decoration: underline;
  cursor: pointer;
  color: var(--brand-dark);
  font-weight: 500;
}

.footer a:hover {
  opacity: 0.65;
}

.footer button {
  cursor: pointer;
  padding: 0.8em 2em;
  background: var(--brand-dark);
  color: rgba(255, 255, 255, 0.85);
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 0;
  border-radius: 50px;
  box-shadow: 0px 3px 10px 0px rgba(var(--brand-dark-rgb), 0.25);
}

.footer button:active {
  box-shadow: 0px 0px 0px 0.25em rgba(var(--brand-dark-rgb), 0.25);
}

.form button[disabled] {
  opacity: 0.75;
}

@media (min-width: 1024px) {
  .form {
    background: transparent;
    border-radius: 0;
    max-width: 500px;
    gap: 1.5em;
  }

  .header h2 {
    font-size: 1.2em;
  }

  .field {
    width: 100%;
  }

  .group {
    flex-direction: row;
    align-items: center;
  }

  .group label {
    width: 120px;
    margin-bottom: 0;
  }

  .footer {
    align-items: flex-start;
  }

  .footer button {
    cursor: pointer;
    padding: 0.5em 2em;
    font-size: 1.25em;
    box-shadow: none;
    transition: all 0.2s ease;
  }

  .footer button:hover {
    /* box-shadow: 0px 3px 10px 0px rgba(var(--brand-dark-rgb), 0.50); */
    box-shadow: 0px 0px 0px 0.25em rgba(var(--brand-dark-rgb), 0.25);
  }
}
