.transaction {
  text-decoration: none !important;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.5rem;
  border-bottom: 1px solid rgba(var(--brand-dark-rgb), 0.05);
  transition: transform 0.2s ease-in-out;
}

.transaction:hover {
  transform: scale(1.05);
  transition: transform 0.2s ease-in-out;

  box-shadow: 0px 0px 0px 0.2em rgba(158, 157, 189, 0.5);
  background-color: #fff;
  border-radius: 1rem;
}

.sideBlock {
  display: flex;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 0.25rem 0.5rem;
  padding-top: 0.2rem;
}

.title {
  overflow: hidden;
  color: #363849;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.subtitle {
  color: rgba(var(--brand-dark-rgb), 0.65);
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.amount {
  overflow: hidden;
  color: #363849;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.amount[state="pending"] {
  color: rgba(var(--brand-dark-rgb), 0.55);
}
.amount[state="error"] {
  color: rgba(var(--brand-danger-rgb), 0.85);
}
.amount[state="pendingcancel"] {
  color: rgba(var(--brand-danger-rgb), 0.65);
}

.date {
  color: rgba(var(--brand-dark-rgb), 0.55);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.icon {
  width: 44px;
  height: 44px;
  background-repeat: no-repeat;
  background-position: center center;
}
.icon[state="pending"] {
  background-image: url(./pending.svg);
}
.icon[state="success"] {
  background-image: url(./success.svg);
}
.icon[state="error"] {
  background-image: url(./error.svg);
}
.icon[state="pendingcancel"] {
  background-image: url(./error.svg);
  opacity: 0.65;
}

.arrow {
  width: 44px;
  height: 44px;
  background-image: url(./arrow.svg);
  background-repeat: no-repeat;
  background-position: center center;
}
