.Status {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2.5rem;
  gap: 1.5rem;
}

.Status .image {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center center;
}

.Status[status="ok"] .image {
  background-image: url(./ok.svg);
}

.Status[status="nok"] .image {
  background-image: url(./notok.svg);
}

.Status .text {
  font-size: 1.25rem;
  font-weight: 600;
  /* text-shadow: 1px 1px 1px rgba(var(--brand-success-rgb), 0.2); */
}

.Status[status="ok"] .text {
  color: var(--brand-success);
}

.Status[status="nok"] .text {
  color: var(--brand-danger);
}
