.wallets {
  display: flex;
  gap: 1em;
  width: 100%;
  flex-direction: column;
  padding: 1em;

  border: var(--test-border-dotted);
  border-color: red;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Transactions {
  background-color: #fff;
  color: var(--brand-text);
  border-radius: 1em;
  background: #fff url(../Form/mobile-page-bg.svg) no-repeat top right;
}

.header {
  display: flex;
  padding: 0.75rem 1rem;
  justify-content: space-between;
  color: rgba(var(--brand-dark-rgb), 0.65);
  font-size: 0.85rem;
  border-bottom: 1px solid rgba(var(--brand-dark-rgb), 0.15);
}

@media (min-width: 1024px) {
  .wallets {
    padding: 2em 1em;
  }

  .Transactions {
    background-color: transparent;
    background: transparent;
  }
}
