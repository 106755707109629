.Button {
  text-decoration: none !important;
  cursor: pointer;
  padding: 0.8em 2em;
  background: var(--brand-dark);
  color: rgba(255, 255, 255, 0.85) !important;
  font-size: 1.25rem !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 0;
  border-radius: 50px;
  box-shadow: 0px 3px 10px 0px rgba(var(--brand-dark-rgb), 0.25);
  background-image: url(./history.svg);
  background-repeat: no-repeat;
  background-position: left 2rem center;
  padding-left: 3.5rem;
}

.Button:hover,
.Button:active {
  box-shadow: 0px 0px 0px 0.25em rgba(var(--brand-dark-rgb), 0.25);
}
