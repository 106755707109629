.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* border: 1px solid red; */
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid rgba(89, 91, 121, 0.1);
  font-size: 0.9rem;
  width: 100%;
}

.item[mode="big"] {
  font-size: 1.15rem;
  margin-bottom: 0.5rem;
  @media (min-width: 1024px) {
    width: 75%;
  }
}

.item[icon="copy"] {
  background-image: url(./copy.svg);
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 1.85rem;
}

.item label {
  color: rgba(var(--brand-dark-rgb), 0.85);
}

.item span {
  color: var(--brand-dark);
  font-weight: bold;
}

.checkboxes {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
}

.checkboxes label {
  font-size: 0.9rem;
  color: rgba(var(--brand-text-rgb), 0.65);
  display: flex;
  align-items: center;
}

.checkboxes input {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: none;
  margin-right: 10px;
  background-color: transparent;
  border-radius: 3px;
  border: 2px solid #595b79;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkboxes input:checked::after {
  content: "\2714";
  color: #595b79;
  font-size: 12px;
}

.help {
  font-size: 0.85rem;
  color: rgba(var(--brand-dark-rgb), 0.45);
  text-align: center;
  padding: 1.15rem 0;
  @media (min-width: 1024px) {
    width: 75%;
  }
}
