.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    max-width: 450px;
    margin: 0 auto;
  }
}

.lead {
  background-color: rgba(255, 255, 255, 0.75);
  padding: 3rem 1rem;
  border-radius: 1rem 1rem 0 0;
  color: var(--brand-dark);
  @media (min-width: 1024px) {
    background-color: var(--brand-dark);
    color: rgba(255, 255, 255, 0.75);
    margin-top: 2rem;
    padding: 1rem;
  }
}

.lead[state="success"] {
  background-color: var(--brand-success);
  color: rgba(255, 255, 255, 0.85);
}
.lead[state="error"] {
  background-color: var(--brand-danger);
  color: rgba(255, 255, 255, 0.85);
}
.lead[state="pendingcancel"] {
  background-color: var(--brand-danger);
  color: rgba(255, 255, 255, 0.85);
}

.amount {
  display: flex;
  gap: 0.25rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.value {
  font-size: 3rem;
}
.currency {
  padding-top: 1.25rem;
  font-size: 1.5rem;
}

.status {
  font-size: 1.25rem;
  font-weight: 400;
}

.content {
  background-color: #fff;
  background: #fff url(components/Form/mobile-page-bg.svg) no-repeat top right;
  padding: 1rem;
  border-radius: 0 0 1rem 1rem;
  @media (min-width: 1024px) {
    background: transparent;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid rgba(89, 91, 121, 0.1);
  font-size: 1.15rem;
  width: 100%;
  background-color: transparent;
}
.item label {
  color: rgba(var(--brand-dark-rgb), 0.85);
}
.item span {
  color: var(--brand-dark);
  font-weight: bold;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  padding-bottom: 2rem;
}
.button {
  text-decoration: none !important;
  cursor: pointer;
  padding: 0.8em 2em;
  background: var(--brand-danger);
  color: rgba(255, 255, 255, 0.85) !important;
  font-size: 1.25rem !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 0;
  border-radius: 50px;
  box-shadow: 0px 3px 10px 0px rgba(var(--brand-danger-rgb), 0.25);
  background-repeat: no-repeat;
  background-position: left 2rem center;
}

.button:hover,
.button:active {
  box-shadow: 0px 0px 0px 0.25em rgba(var(--brand-danger-rgb), 0.25);
}

.button:disabled {
  opacity: 0.5;
}
